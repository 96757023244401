import { defineComponent, inject } from 'vue';
import Header from '@/components/Header.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'Forgot',
  components: {
    HeaderComponent: Header,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    var t = inject('t');
    return {
      isMobile: isMobile,
      t: t
    };
  }
});